import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser'
import PropTypes from 'prop-types'

const ContactForm = ({ page }) => {
  const form = useRef()
  const initialParam = {
    name: '',
    number: '',
    email: '',
    message: '',
  }
  const [param, setParam] = useState(initialParam)
  const [loading, setLoading] = useState(false)
  const [msgStatus, setMsgStatus] = useState({ status: '', message: '' })

  const onChange = (e) => {
    const {
      target: { name, value },
    } = e
    setParam({ ...param, [name]: value })
  }

  const sendEmail = () => {
    setMsgStatus({ status: '', message: '' })
    if (
      Object.entries(param).length !== 0 &&
      Object.entries(param).every(([, value]) => value)
    ) {
      const { name, number, email, message } = param
      setLoading(true)

      emailjs
        .send(
          process.env.GATSBY_EMAIL_JS_SERVICE,
          process.env.GATSBY_EMAIL_JS_TEMPLATE,
          {
            from_page: page,
            from_name: name,
            message,
            reply_to: email,
            bcc_to: 'thea.an@pandacup.co.uk',
            number_to: number,
          },
          process.env.GATSBY_EMAIL_JS_PUBLIC_KEY
        )
        .then(
          () => {
            setParam(initialParam)
            setMsgStatus({
              status: 'success',
              message:
                'Thank you very much for getting in touch. You will hear back from us within 24 hours.',
            })
          },
          () => {
            setMsgStatus({
              status: 'danger',
              message:
                'An unexpected error has occurred please refresh and try again.',
            })
          }
        )
        .finally(() => {
          setLoading(false)
        })
    } else {
      setMsgStatus({
        status: 'danger',
        message: 'Please fill all the fields above',
      })
    }
  }

  return (
    <form className='row pb-5' ref={form}>
      <div className='col-sm-6 '>
        <div className='mb-3'>
          <label htmlFor='name' className='form-label'>
            Name:
          </label>
          <input
            type='text'
            className='form-control w-100 rounded border-0'
            id='name'
            name='name'
            disabled={loading}
            onChange={onChange}
            value={param.name}
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='number' className='form-label'>
            Contact number:
          </label>
          <input
            type='number'
            className='form-control w-100 rounded border-0'
            id='number'
            name='number'
            disabled={loading}
            onChange={onChange}
            value={param.number}
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='email' className='form-label'>
            E-mail address:
          </label>
          <input
            type='text'
            className='form-control w-100 rounded border-0'
            id='email'
            name='email'
            disabled={loading}
            onChange={onChange}
            value={param.email}
          />
        </div>
      </div>
      <div className='col-sm-6 d-flex '>
        <div className='mb-3 flex-grow-1 d-flex flex-column'>
          <label htmlFor='message' className='form-label'>
            Message:
          </label>
          <textarea
            className='form-control w-100 rounded border-0 flex-grow-1'
            id='message'
            name='message'
            rows='8'
            disabled={loading}
            onChange={onChange}
            value={param.message}
          />
        </div>
      </div>
      <div className='col-sm-12'>
        {msgStatus.message && (
          <div className={`alert alert-${msgStatus.status}`} role='alert'>
            {msgStatus.message}
          </div>
        )}
      </div>
      <div className='col-sm-12 text-center mt-3 pt-2'>
        {loading ? (
          <div className='spinner-border' role='status' />
        ) : (
          <button
            type='button'
            className='btn btn-outline-dark'
            onClick={sendEmail}
          >
            Submit
          </button>
        )}
      </div>
    </form>
  )
}

ContactForm.propTypes = {
  page: PropTypes.string.isRequired,
}

export default ContactForm
