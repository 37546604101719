import * as React from 'react'

import Layout from '../components/layout'
import Contact from '../components/Contact/contact'
import SEO from '../components/seo'

const ContactUsPage = () => (
  <Layout>
    <Contact />
  </Layout>
)

export default ContactUsPage

export const Head = () => (
  <SEO
    page='Contact Us'
    description="At Panda Cup, we value our customers' feedback and are always happy to hear from you. Whether you have a question, a suggestion, or just want to say hello, we would love to hear from you."
  />
)
