import React from 'react'
import GoogleMapReact from 'google-map-react'
import ContactBG from '../../assets/images/TOP_contact-us.png'
import Panda from '../../assets/images/Panada.png'
import ContactForm from '../ContactForm'
import { useSiteMetadata } from '../../hooks/use-site-metadata'

const Contact = () => {
  const { locations } = useSiteMetadata()
  const defaultProps = {
    center: {
      lat: 51.5062576,
      lng: -0.1058354,
    },
    zoom: 11,
  }
  return (
    <>
      <div className='fluid'>
        <div className='position-relative overflow-hidden'>
          <img src={ContactBG} alt='Contact Us' className='img-full' />
          <h3 className='text-black font-secondary position-absolute bottom-0 ps-3 d-block d-sm-none'>
            CONTACT US
          </h3>
        </div>
      </div>
      <div className='container text-center px-7'>
        <h1 className='text-black pt-5 font-secondary'>COME AND VISIT US</h1>
        {locations.map((location) => (
          <p key={location.name}>{location.address}</p>
        ))}
        <div className='my-5'>
          <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              {locations.map((location) => (
                <img
                  key={location.name}
                  alt={location.name}
                  width='25px'
                  src={Panda}
                  lat={location.lat} // eslint-disable-line react/no-unknown-property
                  lng={location.lng} // eslint-disable-line react/no-unknown-property
                />
              ))}
            </GoogleMapReact>
          </div>
        </div>
      </div>
      <div className='background-image--contactFormBg'>
        <div className='container px-7'>
          <h1 className='text-black p-5 text-center font-secondary'>
            LARGE RANGE OF SPECIALTY COFFEES
          </h1>
          <ContactForm page='Contact Us' />
        </div>
      </div>
    </>
  )
}

export default Contact
